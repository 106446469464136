import "./style.css";

const ChardCard = ({ image, name }) => {
  return (
    <div>
      <div className="name">{name}</div>
      <img className="image" src={image} alt="" />
    </div>
  );
};

export default ChardCard;
