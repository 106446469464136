import "./style.css";
import ChardCard from "../ChardCard/ChardCard";

const Characters = ({ CharacterList }) => {
  return (
    <div className="characterContent">
      {CharacterList.map((elem) =>
        elem.status === "Alive" ? (
          <div className="characterContent alive" key={elem.id}>
            <ChardCard name={elem.name} image={elem.image} />
          </div>
        ) : elem.status === "Dead" ? (
          <div className="characterContent dead" key={elem.id}>
            <ChardCard name={elem.name} image={elem.image} />
          </div>
        ) : (
          <div className="characterContent unk" key={elem.id}>
            <ChardCard name={elem.name} image={elem.image} />
          </div>
        )
      )}
    </div>
  );
};

export default Characters;
